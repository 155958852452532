<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
    :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl"
    :src="barImage"
    mobile-breakpoint="960"
    app
    mini-variant-width="80"
    width="260"
    v-bind="$attrs"
  >
    <template v-slot:img="props">
      <v-img :gradient="`to bottom, ${barColor}`" v-bind="props" />
    </template>

    <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title class="text-uppercase font-weight-regular display-2">
          <span class="logo-mini">SMG</span>
          <span class="logo-normal">SKYMAP GLOBAL</span>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-divider class="mb-1" />

    <v-list dense nav>
      <base-item-group :item="profile" />
    </v-list>

    <v-divider class="mb-2" />

    <v-list expand nav>
      <div />

      <template v-for="(item, i) in computedItems">
        <base-item-group v-if="item.children" :key="`group-${i}`" :item="item">
        </base-item-group>

        <base-item v-else :key="`item-${i}`" :item="item" />
      </template>

      <div />
    </v-list>
  </v-navigation-drawer>
</template>

<script>
// Utilities
import { mapGetters } from "vuex";

export default {
  name: "DashboardCoreDrawer",

  props: {
    expandOnHover: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({}),

  computed: {
    items() {
      let menus = [];
      if (this.$route.params.code) {
        menus = menus.concat([
          {
            icon: "mdi-information",
            title: "Thông tin",
            to: `/edit/${this.$route.params.code}/information`,
          },
          {
            icon: "mdi-map-marker",
            title: "Loại địa điểm",
            to: `/edit/${this.$route.params.code}/place-types`,
          },
          {
            icon: "mdi-map-marker",
            title: "Địa điểm",
            to: `/edit/${this.$route.params.code}/places`,
          },
          {
            icon: "mdi-map-search",
            title: "Bản đồ",
            to: `/edit/${this.$route.params.code}/maps`,
          },
        ]);
        if (this.$store.state.user.role === "S") {
          menus.push({
            icon: "mdi-office-building",
            title: "Tổ chức",
            to: "/organizations",
          });
        }
      } else {
        if (this.$route.path === "/profile") {
          menus = [
            {
              icon: "mdi-arrow-left",
              title: "Quay lại",
              to: "/",
            },
          ];
        } else
          menus = menus.concat([
            {
              icon: "mdi-office-building",
              title: "Tổ chức",
              to: "/organizations",
            },
            {
              icon: "mdi-briefcase",
              title: "Lĩnh vực kinh doanh",
              to: "/business-areas",
            },
            {
              icon: "mdi-numeric-1-circle-outline",
              title: "Icon",
              to: "/icons",
            },
          ]);
      }

      return menus;
    },
    ...mapGetters(["barColor", "barImage", "name"]),

    drawer: {
      get() {
        return this.$store.state.app.drawer;
      },
      set(val) {
        this.$store.commit("app/SET_DRAWER", val);
      },
    },
    computedItems() {
      return this.items.map(this.mapItem);
    },
    profile() {
      return {
        avatar: true,
        url: this.avatar || `https://ui-avatars.com/api/?name=${"AD"}`,
        group: "",
        title: this.name || "Admin",
        children: [
          {
            to: "profile",
            title: "Hồ sơ cá nhân",
          },
          {
            to: "logout",
            title: "Đăng xuất",
          },
        ],
      };
    },
  },

  watch: {
    "$vuetify.breakpoint.smAndDown"(val) {
      this.$emit("update:expandOnHover", !val);
    },
  },

  methods: {
    mapItem(item) {
      return {
        ...item,
        children: item.children ? item.children.map(this.mapItem) : undefined,
        title: item.title,
      };
    },
  },
};
</script>

<style lang="sass">
@import '~vuetify/src/styles/tools/_rtl.sass'
#core-navigation-drawer
  &.v-navigation-drawer--mini-variant
    .v-list-item
      justify-content: flex-start !important

    .v-list-group--sub-group
      display: block !important

  .v-list-group__header.v-list-item--active:before
    opacity: .24

  .v-list-item
    &__icon--text,
    &__icon:first-child
      justify-content: center
      text-align: center
      width: 20px

      +ltr()
        margin-right: 24px
        margin-left: 12px !important

      +rtl()
        margin-left: 24px
        margin-right: 12px !important

  .v-list--dense
    .v-list-item
      &__icon--text,
      &__icon:first-child
        margin-top: 10px

  .v-list-group--sub-group
    .v-list-item
      +ltr()
        padding-left: 8px

      +rtl()
        padding-right: 8px

    .v-list-group__header
      +ltr()
        padding-right: 0

      +rtl()
        padding-right: 0

      .v-list-item__icon--text
        margin-top: 19px
        order: 0

      .v-list-group__header__prepend-icon
        order: 2

        +ltr()
          margin-right: 8px

        +rtl()
          margin-left: 8px
</style>
